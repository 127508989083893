/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import {
  Button, Container, Grid, Typography,
} from '@mui/material';
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo';
import { navigate } from 'gatsby-link';
import Layout, { LayoutContext } from '../../components/Layout';
import golfGroup from '../../img/events.png';
import business from '../../img/leads/business.jpg';

function PlayPage({ location }) {
  const { mobileMode } = React.useContext(LayoutContext);
  const [waitlistFormState, setWaitlistFormState] = useState({ isValidated: true });

  function encode(data) {
    return Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  }

  React.useEffect(() => {
    const scrollToElement = () => {
      const target = document.querySelector(location.state.scrollTo);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      } else {
        setTimeout(scrollToElement, 100);
      }
    };

    if (location.state && location.state.scrollTo) {
      scrollToElement();
    }
  }, [location]);

  const handleWaitlistChange = (e) => {
    setWaitlistFormState({ ...waitlistFormState, [e.target.name]: e.target.value });
  };

  const handleWaitlistSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...waitlistFormState,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <div>
      <Container maxWidth="lg" sx={mobileMode ? { py: 4 } : { pt: 3, pb: 14 }}>
        <Grid container direction="column" rowGap={3}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            direction={mobileMode ? 'column' : 'row'}
            rowGap={3}
          >
            <Grid item xs={12} md={7}>
              <Grid container direction="column" rowGap={2}>
                <Typography
                  variant="h1"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Viga',
                        fontSize: '36px',
                        fontWeight: '400',
                        lineHeight: '45px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                      }
                      : {
                        fontFamily: 'Viga',
                        fontSize: '62px',
                        fontWeight: '400',
                        lineHeight: '90px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                      }
                  }
                >
                  Monetize your followers with in-person events and experiences.
                </Typography>
                <Typography
                  variant="body1"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Mulish',
                        fontSize: '24px',
                        fontWeight: '400',
                        lineHeight: '35px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: '#8C959F',
                      }
                      : {
                        fontFamily: 'Mulish',
                        fontSize: '28px',
                        fontWeight: '400',
                        lineHeight: '40px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#8C959F',
                      }
                  }
                >
                  Host IRL events with your followers as a new engagement and revenue opportunity.
                </Typography>
                <Grid container justifyContent="flexStart" sx={{ pt: 2 }}>
                  <Button
                    style={{
                      backgroundColor: '#5AB862',
                      fontFamily: 'Open Sans',
                      fontSize: '16px',
                      color: 'white',
                      fontWeight: '600',
                      lineHeight: '22px',
                      letterSpacing: '0em',
                      borderRadius: '100px',
                      height: '60px',
                      width: mobileMode ? '100%' : '60%',
                      alignSelf: 'center',
                    }}
                    href="/business#business"
                  >
                    Join Waitlist
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <img
                src={golfGroup}
                alt="Example of golf group in the application over green background"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="none"
        sx={
          mobileMode
            ? { backgroundColor: '#5AB862', py: 4 }
            : { backgroundColor: '#5AB862', py: 10 }
        }
        id="business"
      >
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            direction={mobileMode ? 'column' : 'row'}
            rowGap={3}
            justifyContent="space-between"
          >
            <Grid item xs={12} md={6}>
              <img
                src={business}
                alt="Example of golf group in the application over green background"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid
                container
                direction="column"
                rowGap={mobileMode ? 3 : 5}
                alignItems={mobileMode ? 'center' : 'flex-start'}
              >
                <Typography
                  variant="h2"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Poppins',
                        fontSize: '29px',
                        fontWeight: '600',
                        lineHeight: '39px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        color: 'white',
                      }
                      : {
                        fontFamily: 'Poppins',
                        fontSize: '41px',
                        fontWeight: '600',
                        lineHeight: '64px',
                        letterSpacing: '0em',
                        color: 'white',
                      }
                  }
                >
                  Business Beyond The Screen: Sign Up for Squaddie and Host Networking Events For
                  Your Followers!
                </Typography>
                <Typography
                  variant="body1"
                  style={
                    mobileMode
                      ? {
                        fontFamily: 'Mulish',
                        fontSize: '24px',
                        fontWeight: '400',
                        lineHeight: '40px',
                        letterSpacing: '0em',
                        color: 'white',
                        textAlign: 'center',
                      }
                      : {
                        fontFamily: 'Mulish',
                        fontSize: '24px',
                        fontWeight: '400',
                        lineHeight: '40px',
                        letterSpacing: '0em',
                        color: 'white',
                      }
                  }
                >
                  Helping business influencers engage with and monetize their followers by hosting
                  IRL events. Join the waitlist for Squaddie, the app that takes care of
                  registration, payment, and execution!
                </Typography>
                <form
                  className="launchlist-form"
                  action="https://getlaunchlist.com/s/IDmRYe"
                  method="POST"
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="business" />
                  <div hidden>
                    <label>
                      Don’t fill this out:
                      <input name="business-bot-field" onChange={handleWaitlistChange} />
                    </label>
                  </div>
                  <Grid container direction="column" rowGap={2}>
                    <Grid item>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name="email"
                          onChange={handleWaitlistChange}
                          id="business_email"
                          required
                          placeholder="Email Address"
                          style={{
                            borderColor: '#D3D3D3',
                            borderRadius: '41px',
                            fontFamily: 'Mulish',
                            fontSize: '20px',
                            fontWeight: '600',
                            lineHeight: '25px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            height: '60px',
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid container columnGap={2} rowGap={2}>
                      <Grid item xs="12" md="5.5">
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="full_name"
                            onChange={handleWaitlistChange}
                            id="full_name"
                            required
                            placeholder="Full Name"
                            style={{
                              borderColor: '#D3D3D3',
                              borderRadius: '41px',
                              fontFamily: 'Mulish',
                              fontSize: '20px',
                              fontWeight: '600',
                              lineHeight: '25px',
                              letterSpacing: '0em',
                              textAlign: 'left',
                              height: '60px',
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs="12" md="5.5">
                        <div className="control">
                          <input
                            className="input"
                            type="text"
                            name="location"
                            onChange={handleWaitlistChange}
                            id="location"
                            required
                            placeholder="Location"
                            style={{
                              borderColor: '#D3D3D3',
                              borderRadius: '41px',
                              fontFamily: 'Mulish',
                              fontSize: '20px',
                              fontWeight: '600',
                              lineHeight: '25px',
                              letterSpacing: '0em',
                              textAlign: 'left',
                              height: '60px',
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container justifyContent="center">
                        <button
                          className="button is-link"
                          type="submit"
                          style={{
                            backgroundColor: 'black',
                            fontFamily: 'Open Sans',
                            fontSize: '16px',
                            fontWeight: '600',
                            lineHeight: '22px',
                            letterSpacing: '0em',
                            borderRadius: '100px',
                            height: '60px',
                            width: mobileMode ? '100%' : '60%',
                            alignSelf: 'center',
                          }}
                        >
                          Join Waitlist
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
}

function PlayIndexPage({ location }) {
  return (
    <Layout location={location}>
      <GatsbySeo
        title="Empower Your Influence: Monetize and Engage with Squaddie's Influencer Platform"
        description="Unlock the full potential of your online presence with Squaddie, the premier influencer platform. Monetize your influence and boost engagement through immersive in-person events. Join the waitlist to access a suite of tools that streamline registration, payment, and execution. Elevate your digital influence and revenue opportunities with Squaddie. Sign up now!"
        canonical="https://www.squaddie.co/business"
      />
      <JsonLd
        url="https://www.squaddie.com/business"
        datePublished="2023-11-29T08:00:00+08:00"
        // dateModified="2023-03-10T09:00:00+08:00"
        authorName="Squaddie"
        authorType="Organization"
        description="Unlock the full potential of your online presence with Squaddie, the premier influencer platform. Monetize your influence and boost engagement through immersive in-person events. Join the waitlist to access a suite of tools that streamline registration, payment, and execution. Elevate your digital influence and revenue opportunities with Squaddie. Sign up now!"
        overrides={{
          '@type': 'SoftwareApplication',
          name: 'Squaddie',
          applicationCategory: 'UtilitiesApplication',
        }}
      />
      <PlayPage location={location} />
      <script src="https://getlaunchlist.com/js/widget-diy.js" defer />
    </Layout>
  );
}

export default PlayIndexPage;
